.navbar {
  background-color: #f3f3f3;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.nav-menu {
  background-color: #000000;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #ffffff;
  font-size: 17px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin-left: 4px;
}

.nav-text a:hover {
  background-color: #252525;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navIc {
  align-items: center;
  justify-content: center;
}

.navTitle {
  font-size: 20px;
  font-weight: bold;
  color: #b1b1b1;
  margin-left: 30px;
  display: flex;
}

.navInner {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}

span {
  margin-left: 16px;
}
