.SquadItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.SquadItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.SquadItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.SquadItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.SquadItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.SquadItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.SquadItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.SquadItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.SquadItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}

.PopupTitle {
    font-size: 17px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
    /* margin-left: 10px; */
}

.PopupDescription {
    font-size: 15px;
    font-weight: normal;
    color: rgb(117, 99, 39);
    padding: 20px;
    text-align: center;
    /* margin-left: 10px; */
}

.PopupApproveBtn {
    background-color: rgb(0, 166, 243); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupRemoveBtn {
    background-color: rgb(233, 35, 35); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupCloseBtn {
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    border: none;
}

.PopupCloseBtn:focus {outline:0;}

.SquadItemViewPostsBtn {
    background-color: rgb(192, 192, 192);
    font-size: 13px;
    font-weight: normal;
    color: white;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 5px;
    width: 125px;
    border-radius: 5px;
    margin-top: 10px;
    text-decoration: none;
    display: flex;
}

.SquadItemViewPostsBtn:focus {outline:0;}

