@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.logoutBtn {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  background: rgb(0, 0, 0);
  height: 40px;
  width: 40px;
  border-radius: 20px 20px 20px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.navbar {
  background-color: #f3f3f3;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.nav-menu {
  background-color: #000000;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #ffffff;
  font-size: 17px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin-left: 4px;
}

.nav-text a:hover {
  background-color: #252525;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navIc {
  align-items: center;
  justify-content: center;
}

.navTitle {
  font-size: 20px;
  font-weight: bold;
  color: #b1b1b1;
  margin-left: 30px;
  display: flex;
}

.navInner {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}

span {
  margin-left: 16px;
}

.Container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  /* background-color: red; */
  /* height: 100vh; */
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.TopHeader {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  height: 200px;
  width: 100vw;
  margin-left: 0px;
  margin-right: 0px;
}

.Logo {
  height: 32px;
  width: auto;
  margin-right: 15px;
  object-fit: contain;
}

.Title {
  font-weight: 700;
  font-size: 32px;
  color: rgb(255, 255, 255);
}

.Heading {
  color: black;
  font-size: 15px;
  font-weight: normal;
  padding-right: 0px;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.InputContainer {
  margin-top: 100px;
  margin-bottom: 55px;
}

.Input {
  background-color: #e8e8e8;
  border-radius: 5px;
  border-width: 0px 0px 0px 0px;
  /* border-bottom-color: rgb(219, 219, 219); */
  /* height: 40px; */
  margin-top: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  font-size: 16px;
  outline: none;
  width: 250px;
}

.LoginBtn {
  height: 55px;
  width: 200px;
  background-color: #ff1443;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 27.5px;
  text-decoration: none;
}

.LoginTxt {
  /* font-weight: 900; */
  font-size: 17px;
  color: rgb(255, 255, 255);
}

.errorContainer {
  margin-top: 10px;
  height: 15px;
  width: auto;
}

.errorTxt {
  color: #ff1443;
  font-size: 15px;
  font-weight: bold;
  padding-right: 0px;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}


.BlockedUserContainer {
    background-color: #f0f0f0;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 10px;
    /* width: 100%; */
    /* margin-right: 10px;
    margin-left: 10px; */
    border-radius: 5px;
}

.BlockedUserImage {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #e8e8e8;
}

.BlockedUserName {
    font-size: 17px;
    font-weight: normal;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
}
.BlackListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
s .BlackListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.PartyItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.PartyItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.PartyItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.PartyItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.PartyItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.PartyItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.PartyItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.PartyItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.PartyItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}

.PopupTitle {
    font-size: 17px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
    /* margin-left: 10px; */
}

.PopupDescription {
    font-size: 15px;
    font-weight: normal;
    color: rgb(117, 99, 39);
    padding: 20px;
    text-align: center;
    /* margin-left: 10px; */
}

.PopupApproveBtn {
    background-color: rgb(0, 166, 243); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupRemoveBtn {
    background-color: rgb(233, 35, 35); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupCloseBtn {
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    border: none;
}

.PopupCloseBtn:focus {outline:0;}

.PartyItemViewPostsBtn {
    background-color: rgb(192, 192, 192);
    font-size: 13px;
    font-weight: normal;
    color: white;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 5px;
    width: 125px;
    border-radius: 5px;
    margin-top: 10px;
    text-decoration: none;
    display: flex;
}

.PartyItemViewPostsBtn:focus {outline:0;}
.PartyItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.PartyItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.PartyItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.PartyItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.PartyItemSuspendedTime {
    font-size: 14px;
    font-weight: normal;
    color: rgb(180, 41, 41);
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
}

.PartyItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.PartyItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.PartyItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.PartyItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.PartyItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}
.PostsListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.PostsListInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.PostsListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  /* padding: 15px; */
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  /* width: 50%; */
  /* background: #f0f0f0; */
  background: white;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  /* border-right: 1px solid #cecece; */
}

.active-tabs {
  /* background: #f0f0f0; */
  /* border-bottom: 1px solid transparent; */
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  /* background: #ba5af2; */
  background: rgb(8, 159, 247);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}

.content {
  background: rgb(255, 255, 255);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

.SmallPostContainer {
  /* background-color: #f0f0f0; */
  /* background-color: red; */
  /* margin-top: 5px;
    margin-bottom: 5px; */
  /* flex-direction: row; */
  /* display: flex; */
  /* align-items: center; */
  /* padding: 10px; */
  /* width: 100%; */
  /* margin-right: 10px;
    margin-left: 10px; */
  /* border-radius: 5px; */

  width: 25%;
  display: flex;
  flex: none;
  align-content: stretch;
  padding-left: 2.5px;
  padding-right: 2.5px;
  padding-bottom: 10px;
}

.SmallPostInner {
  background-color: #f0f0f0;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  width: 100%;
}

.SmallPostImage {
  /* height: 50px; */
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  background-color: #e8e8e8;
}

.SmallPostUsername {
  font-size: 17px;
  font-weight: normal;
  color: black;
  /* padding-left: 10px; */
  padding-right: 10px;
  margin-top: 10px;
}

.SmallPostReportCounter {
  font-size: 15px;
  font-weight: normal;
  color: rgb(43, 43, 43);
  /* padding-left: 10px; */
  padding-right: 10px;
  margin-top: 10px;
}

.SmallPostButtonsContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}

.SmallPostApproveBtn {
  height: 40px;
  width: 100%;
  margin-right: 5px;
  background-color: rgb(0, 166, 243);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.SmallPostDenyBtn {
  height: 40px;
  width: 100%;
  margin-left: 5px;
  border-radius: 5px;
  background-color: rgb(233, 35, 35);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.ConfirmIc {
  align-items: center;
  justify-content: center;
}

.ConfirmTxt {
  font-size: 16px;
  font-weight: normal;
  color: white;
  margin-left: 10px;
}

.VideoBtn {
  height: 5%;
  width: 5%;
  margin-top: 10px;
  margin-left: 10px;
  /* z-index: 1000; */
  position: absolute;
}

.VideoBtn {
  -webkit-filter: drop-shadow(1px 1px 3px rgb(165, 165, 165));
          filter: drop-shadow(1px 1px 3px rgb(165, 165, 165));
}

.SmallPostImageModal:focus {
  outline: 0;
}

.ImageModalCloseBtn {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  height: 40px;
  width: 40px;
  text-decoration: none;
  border: none;
}

.ImageModalCloseBtn {
  -webkit-filter: drop-shadow(0px 0px 1.5px rgb(134, 134, 134));
          filter: drop-shadow(0px 0px 1.5px rgb(134, 134, 134));
}

.VideoPopup {
  flex: 1 1;
}
.ImagePopup {
  flex: 1 1;
}

.PostsListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.PostsListInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.PostsListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.bloc-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs {
  /* padding: 15px; */
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  /* width: 50%; */
  /* background: #f0f0f0; */
  background: white;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  /* border-right: 1px solid #cecece; */
}

.active-tabs {
  /* background: #f0f0f0; */
  /* border-bottom: 1px solid transparent; */
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  /* background: #ba5af2; */
  background: rgb(8, 159, 247);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}

.content {
  background: rgb(255, 255, 255);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}


.ProfilePostContainer {
    /* background-color: #f0f0f0; */
    /* background-color: red; */
    /* margin-top: 5px;
    margin-bottom: 5px; */
    /* flex-direction: row; */
    /* display: flex; */
    /* align-items: center; */
    /* padding: 10px; */
    /* width: 100%; */
    /* margin-right: 10px;
    margin-left: 10px; */
    /* border-radius: 5px; */

    width: 50%;
    display: flex;
    flex: none;
    align-content: stretch;
    padding-left: 2.5px;
    padding-right: 2.5px;
    padding-bottom: 10px;
}

.ProfilePostInner {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    aspect-ratio: 3/4;
}

.ProfilePostImage {
    /* height: 50px; */
    width: auto;
    height: 100%;
    /* border-radius: 5px; */
    background-color: #e8e8e8;
}
.ProfilePostsContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.ProfilePostsInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.ProfilePostsUsername {
  font-size: 22px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.SquadItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.SquadItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.SquadItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.SquadItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.SquadItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.SquadItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.SquadItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.SquadItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.SquadItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}

.PopupTitle {
    font-size: 17px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
    /* margin-left: 10px; */
}

.PopupDescription {
    font-size: 15px;
    font-weight: normal;
    color: rgb(117, 99, 39);
    padding: 20px;
    text-align: center;
    /* margin-left: 10px; */
}

.PopupApproveBtn {
    background-color: rgb(0, 166, 243); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupRemoveBtn {
    background-color: rgb(233, 35, 35); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupCloseBtn {
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    border: none;
}

.PopupCloseBtn:focus {outline:0;}

.SquadItemViewPostsBtn {
    background-color: rgb(192, 192, 192);
    font-size: 13px;
    font-weight: normal;
    color: white;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 5px;
    width: 125px;
    border-radius: 5px;
    margin-top: 10px;
    text-decoration: none;
    display: flex;
}

.SquadItemViewPostsBtn:focus {outline:0;}


.SquadItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.SquadItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.SquadItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.SquadItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.SquadItemSuspendedTime {
    font-size: 14px;
    font-weight: normal;
    color: rgb(180, 41, 41);
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
}

.SquadItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.SquadItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.SquadItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.SquadItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.SquadItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}
.PostsListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.PostsListInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.PostsListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  /* padding: 15px; */
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  /* width: 50%; */
  /* background: #f0f0f0; */
  background: white;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  /* border-right: 1px solid #cecece; */
}

.active-tabs {
  /* background: #f0f0f0; */
  /* border-bottom: 1px solid transparent; */
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  /* background: #ba5af2; */
  background: rgb(8, 159, 247);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}

.content {
  background: rgb(255, 255, 255);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

.UserItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.UserItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.UserItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.UserItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.UserItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.UserItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.UserItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.UserItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.UserItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}

.PopupTitle {
    font-size: 17px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
    /* margin-left: 10px; */
}

.PopupDescription {
    font-size: 15px;
    font-weight: normal;
    color: rgb(117, 99, 39);
    padding: 20px;
    text-align: center;
    /* margin-left: 10px; */
}

.PopupApproveBtn {
    background-color: rgb(0, 166, 243); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block; 
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupRemoveBtn {
    background-color: rgb(233, 35, 35); /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: 40px;
  margin-top: 30px;
}

.PopupCloseBtn {
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 40px;
    width: 40px;
    text-decoration: none;
    border: none;
}

.SmallPostImageModal:focus {outline:0;}

.UserItemViewPostsBtn {
    background-color: rgb(192, 192, 192);
    font-size: 13px;
    font-weight: normal;
    color: white;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 5px;
    width: 125px;
    border-radius: 5px;
    margin-top: 10px;
    text-decoration: none;
    display: flex;
}

.UserItemViewPostsBtn:focus {outline:0;}
.UserItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.UserItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.UserItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.UserItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.UserItemSuspendedTime {
    font-size: 14px;
    font-weight: normal;
    color: rgb(180, 41, 41);
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
}

.UserItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.UserItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.UserItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.UserItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.UserItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}
.PostsListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.PostsListInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.PostsListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  /* padding: 15px; */
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  /* width: 50%; */
  /* background: #f0f0f0; */
  background: white;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  /* border-right: 1px solid #cecece; */
}

.active-tabs {
  /* background: #f0f0f0; */
  /* border-bottom: 1px solid transparent; */
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  /* background: #ba5af2; */
  background: rgb(8, 159, 247);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}

.content {
  background: rgb(255, 255, 255);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

