.Container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  /* background-color: red; */
  /* height: 100vh; */
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.TopHeader {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  height: 200px;
  width: 100vw;
  margin-left: 0px;
  margin-right: 0px;
}

.Logo {
  height: 32px;
  width: auto;
  margin-right: 15px;
  object-fit: contain;
}

.Title {
  font-weight: 700;
  font-size: 32px;
  color: rgb(255, 255, 255);
}

.Heading {
  color: black;
  font-size: 15px;
  font-weight: normal;
  padding-right: 0px;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.InputContainer {
  margin-top: 100px;
  margin-bottom: 55px;
}

.Input {
  background-color: #e8e8e8;
  border-radius: 5px;
  border-width: 0px 0px 0px 0px;
  /* border-bottom-color: rgb(219, 219, 219); */
  /* height: 40px; */
  margin-top: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  font-size: 16px;
  outline: none;
  width: 250px;
}

.LoginBtn {
  height: 55px;
  width: 200px;
  background-color: #ff1443;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 27.5px;
  text-decoration: none;
}

.LoginTxt {
  /* font-weight: 900; */
  font-size: 17px;
  color: rgb(255, 255, 255);
}

.errorContainer {
  margin-top: 10px;
  height: 15px;
  width: auto;
}

.errorTxt {
  color: #ff1443;
  font-size: 15px;
  font-weight: bold;
  padding-right: 0px;
  padding-left: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}
