
.ProfilePostContainer {
    /* background-color: #f0f0f0; */
    /* background-color: red; */
    /* margin-top: 5px;
    margin-bottom: 5px; */
    /* flex-direction: row; */
    /* display: flex; */
    /* align-items: center; */
    /* padding: 10px; */
    /* width: 100%; */
    /* margin-right: 10px;
    margin-left: 10px; */
    /* border-radius: 5px; */

    width: 50%;
    display: flex;
    flex: none;
    align-content: stretch;
    padding-left: 2.5px;
    padding-right: 2.5px;
    padding-bottom: 10px;
}

.ProfilePostInner {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    aspect-ratio: 3/4;
}

.ProfilePostImage {
    /* height: 50px; */
    width: auto;
    height: 100%;
    /* border-radius: 5px; */
    background-color: #e8e8e8;
}