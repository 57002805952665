.BlackListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
s .BlackListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}
