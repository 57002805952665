.PartyItemContainer {
    background-color: #f0f0f0;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px;
    border-radius: 5px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.PartyItemInner {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.PartyItemImage {
    height: 200px;
    width: 200px;
    margin-right: 10px;
    border-radius: 100px;
    background-color: #e8e8e8;
}

.PartyItemUsername {
    font-size: 17px;
    font-weight: normal;
    color: black;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
    word-wrap: break-word;
}

.PartyItemSuspendedTime {
    font-size: 14px;
    font-weight: normal;
    color: rgb(180, 41, 41);
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-top: 10px;
}

.PartyItemSeparator {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(218, 218, 218);
}

.PartyItemReportCounter {
    font-size: 15px;
    font-weight: normal;
    color: rgb(43, 43, 43);
}

.PartyItemButtonsContainer {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.PartyItemApproveBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 5px;
    background-color: rgb(0, 166, 243);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.PartyItemDenyBtn {
    height: 40px;
    max-width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: rgb(233, 35, 35);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.ConfirmIc {
    align-items: center;
    justify-content: center;
}

.ConfirmTxt {
    font-size: 16px;
    font-weight: normal;
    color: white;
    margin-left: 10px;
}