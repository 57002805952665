.SmallPostContainer {
  /* background-color: #f0f0f0; */
  /* background-color: red; */
  /* margin-top: 5px;
    margin-bottom: 5px; */
  /* flex-direction: row; */
  /* display: flex; */
  /* align-items: center; */
  /* padding: 10px; */
  /* width: 100%; */
  /* margin-right: 10px;
    margin-left: 10px; */
  /* border-radius: 5px; */

  width: 25%;
  display: flex;
  flex: none;
  align-content: stretch;
  padding-left: 2.5px;
  padding-right: 2.5px;
  padding-bottom: 10px;
}

.SmallPostInner {
  background-color: #f0f0f0;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  width: 100%;
}

.SmallPostImage {
  /* height: 50px; */
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  background-color: #e8e8e8;
}

.SmallPostUsername {
  font-size: 17px;
  font-weight: normal;
  color: black;
  /* padding-left: 10px; */
  padding-right: 10px;
  margin-top: 10px;
}

.SmallPostReportCounter {
  font-size: 15px;
  font-weight: normal;
  color: rgb(43, 43, 43);
  /* padding-left: 10px; */
  padding-right: 10px;
  margin-top: 10px;
}

.SmallPostButtonsContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}

.SmallPostApproveBtn {
  height: 40px;
  width: 100%;
  margin-right: 5px;
  background-color: rgb(0, 166, 243);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.SmallPostDenyBtn {
  height: 40px;
  width: 100%;
  margin-left: 5px;
  border-radius: 5px;
  background-color: rgb(233, 35, 35);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.ConfirmIc {
  align-items: center;
  justify-content: center;
}

.ConfirmTxt {
  font-size: 16px;
  font-weight: normal;
  color: white;
  margin-left: 10px;
}

.VideoBtn {
  height: 5%;
  width: 5%;
  margin-top: 10px;
  margin-left: 10px;
  /* z-index: 1000; */
  position: absolute;
}

.VideoBtn {
  filter: drop-shadow(1px 1px 3px rgb(165, 165, 165));
}

.SmallPostImageModal:focus {
  outline: 0;
}

.ImageModalCloseBtn {
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  height: 40px;
  width: 40px;
  text-decoration: none;
  border: none;
}

.ImageModalCloseBtn {
  filter: drop-shadow(0px 0px 1.5px rgb(134, 134, 134));
}

.VideoPopup {
  flex: 1;
}
.ImagePopup {
  flex: 1;
}
