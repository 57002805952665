.PostsListContainer {
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.PostsListInner {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 12px; */
  display: flex;
  flex-wrap: wrap;
}

.PostsListTitle {
  font-size: 50px;
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 15px;
}

.LoadMoreBtn {
  font-size: 14px;
  color: white;
  background-color: black;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  /* padding: 15px; */
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  /* width: 50%; */
  /* background: #f0f0f0; */
  background: white;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  /* border-right: 1px solid #cecece; */
}

.active-tabs {
  /* background: #f0f0f0; */
  /* border-bottom: 1px solid transparent; */
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 2px;
  /* background: #ba5af2; */
  background: rgb(8, 159, 247);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}

.content {
  background: rgb(255, 255, 255);
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}
