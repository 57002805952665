@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.logoutBtn {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  background: rgb(0, 0, 0);
  height: 40px;
  width: 40px;
  border-radius: 20px 20px 20px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}
