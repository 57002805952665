
.BlockedUserContainer {
    background-color: #f0f0f0;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 10px;
    /* width: 100%; */
    /* margin-right: 10px;
    margin-left: 10px; */
    border-radius: 5px;
}

.BlockedUserImage {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #e8e8e8;
}

.BlockedUserName {
    font-size: 17px;
    font-weight: normal;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
}